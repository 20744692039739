import { PatientDiagnose } from '../treatmentPlan.state'
import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

interface Request {
  diagosisCategoryId: number
  diagosisId: number
  subDiagnosisId: number
  treatmentToothId: number[]
  diagnosisSpecifierId: number
}

interface Response {
  [key: number]: PatientDiagnose[]
}

export const createToothDiagnose = createAsyncThunk<Response, Request>(
  'treatmentPlan/create-tooth-diagnose',
  async (credentials, thunkAPI) => {
    try {
      const response = await axios.post('patient-diagnosis', credentials)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
