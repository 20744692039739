import { CSSProperties, FC, MouseEvent, ReactNode } from 'react'

import { StyledButton } from './Button.styled'

interface ButtonProps {
  children: ReactNode
  className?: string
  disabled?: boolean
  type?: 'button' | 'submit' | 'reset'
  color?: 'lightblue' | 'blue' | 'red' | 'transparent'
  style?: CSSProperties
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
  active?: boolean
}

const Button: FC<ButtonProps> = ({
  active = false,
  type = 'button',
  disabled = false,
  className,
  style,
  color = 'lightblue',
  onClick,
  children,
}) => {
  return (
    <StyledButton
      styledColor={color}
      type={type}
      isActive={active}
      onClick={onClick}
      style={{ ...style }}
      className={className}
      disabled={disabled}
    >
      {children}
    </StyledButton>
  )
}

export default Button
