import { AppointmentStage, initialState } from './treatmentPlan.state'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  PayloadAppointmentStage,
  PayloadChangeStageIntoAppointment,
  PayloadMoveAppointments,
  PayloadStageIntoAppointment,
  PayloadStagesIntoApp,
  SelectTooth,
} from './treatmentPlan.types'

import { addReviewData } from './operations/add-review-data.operation'
import { addTreatmentPlanImage } from './operations/add-treatment-plan-image.operation'
import { changeMainVariant } from './operations/change-main-variant.operation'
import { changeRotationTreatmentPlanImage } from './operations/change-image-rotation.operation'
import { changeToothInfo } from './operations/change-tooth-description.operation'
import { changeTreatmentPlan } from './operations/change-treatment-plan-name.operation'
import { createNewAppointment } from './operations/create-new-appointment.operation'
import { createToothDiagnose } from './operations/create-tooth-diagnose.operation'
import { createToothPriority } from './operations/create-tooth-priority.operation'
import { createToothTreatment } from './operations/create-tooth-treatment.operation'
import { createVariant } from './operations/create-variant.operation'
import { deleteAllAppointments } from './operations/delete-all-appointments.operation'
import { deleteAppointment } from './operations/delete-appointment.operation'
import { deleteAppointmentStageById } from './operations/delete-stage-by-id.operation'
import { deleteToothDiagnose } from './operations/delete-tooth-diagnose.operation'
import { deleteToothPriority } from './operations/delete-tooth-priority.operation'
import { deleteToothTreatment } from './operations/delete-tooth-treatment.operation'
import { deleteTreatmentPlanImage } from './operations/delete-treatment-plan-image.operation'
import { deleteVariant } from './operations/delete-variant.operation'
import { getToothDescription } from './operations/get-tooth-description.operation'
import { getTreatmentAppointmentsById } from './operations/get-treatment-appointments.operation'
import { getTreatmentPlanById } from './operations/get-treatment-plan-id.operation'
import { saveMetadataTreatmentPlanImage } from './operations/save-metadata-image.operation'
import { saveStagesIntoAppointments } from './operations/save-appointment-stages.operation'

const treatmentPlanSlice = createSlice({
  name: 'treatmentPlan',
  initialState,
  reducers: {
    addSelectedTooth: (state, action: PayloadAction<SelectTooth>) => {
      // if (state.selectedTooth === action.payload.toothId) {
      //   state.selectedTooth = null
      //   return
      // }
      if (action.payload.type === 'appointment') {
        const appointments =
          state.treatmentAppointments.teeth[action.payload.toothId].patientTreatments

        if (Object.values(appointments).length) {
          state.selectedTooth = action.payload.toothId
        }
      } else if (action.payload.type === 'review') {
        const treatments =
          state.treatmentAppointments?.teeth[action.payload.toothId].patientTreatments
        const diagnosis = state.treatmentPlan?.teeth[action.payload.toothId].patientDiagnoses
        if (!treatments || !diagnosis) return
        if (Object.values(treatments).length || Object.values(diagnosis).length) {
          state.selectedTooth = action.payload.toothId
        }
      } else {
        state.selectedTooth = action.payload.toothId
      }
    },
    updateSelectedTeeth: (state, action: PayloadAction<number>) => {
      if (state.selectedTeeth.includes(action.payload)) {
        state.selectedTeeth = state.selectedTeeth.filter((el) => el !== action.payload)
      } else {
        state.selectedTeeth.push(action.payload)
      }
    },
    addPath: (state, action: PayloadAction<string>) => {
      state.navigationPath = action.payload
    },
    clearPathAndSave: (state) => {
      state.navigationPath = ''
      state.needAppointmentSave = false
    },
    addAllStagesIntoAppointment: (state, action: PayloadAction<PayloadStagesIntoApp>) => {
      const { stages, appointmentId, index } = action.payload
      state.needAppointmentSave = true
      const appointmentStages =
        state.treatmentAppointments.appointments[appointmentId].appointmentStage

      // Создаем новый массив, вставляя новые этапы в указанный индекс
      const newStages = [
        ...appointmentStages.slice(0, index),
        ...stages,
        ...appointmentStages.slice(index),
      ]

      // Пересчитываем sortOrder для каждого этапа
      const updatedStages = newStages.map((stage, idx) => ({
        ...stage,
        sortOrder: idx,
      }))

      // Обновляем этапы в состоянии
      state.treatmentAppointments.appointments[appointmentId].appointmentStage = updatedStages
    },
    addStageIntoAppointment: (state, action: PayloadAction<PayloadStageIntoAppointment>) => {
      const { stage, appointmentId, index } = action.payload
      state.needAppointmentSave = true

      const appointmentStages =
        state.treatmentAppointments.appointments[appointmentId].appointmentStage

      const newStages = [
        ...appointmentStages.slice(0, index),
        stage,
        ...appointmentStages.slice(index),
      ]

      const updatedStages = newStages.map((stage, idx) => ({
        ...stage,
        sortOrder: idx,
      }))

      // Обновляем этапы в состоянии
      state.treatmentAppointments.appointments[appointmentId].appointmentStage = updatedStages
    },
    changeStageIntoAppointment: (
      state,
      action: PayloadAction<PayloadChangeStageIntoAppointment>,
    ) => {
      const { sourceAppointmentId, sourceIndex, destinationAppointmentId, destinationIndex } =
        action.payload

      if (sourceAppointmentId === destinationAppointmentId) {
        const appointmentStages =
          state.treatmentAppointments.appointments[sourceAppointmentId].appointmentStage
        state.needAppointmentSave = true

        const newStages = [...appointmentStages]
        const [removedStage] = newStages.splice(sourceIndex, 1)

        newStages.splice(destinationIndex, 0, removedStage)

        const updatedStages = newStages.map((stage, idx) => ({
          ...stage,
          sortOrder: idx,
        }))
        state.treatmentAppointments.appointments[sourceAppointmentId].appointmentStage =
          updatedStages
      } else {
        const sourceAppointmentStages =
          state.treatmentAppointments.appointments[sourceAppointmentId].appointmentStage

        const removedStage = sourceAppointmentStages.find((_, index) => index === sourceIndex)

        removedStage.appointmentId = Number(destinationAppointmentId)

        const destinationAppointmentStages =
          state.treatmentAppointments.appointments[destinationAppointmentId].appointmentStage

        const newStages = [...destinationAppointmentStages]
        newStages.splice(destinationIndex, 0, removedStage)
        const updatedStages = newStages.map((stage, idx) => ({
          ...stage,
          sortOrder: idx,
        }))

        state.treatmentAppointments.appointments[destinationAppointmentId].appointmentStage =
          updatedStages
        state.treatmentAppointments.appointments[sourceAppointmentId].appointmentStage =
          sourceAppointmentStages.filter((el) => el.id !== removedStage.id)
      }
    },
    deleteApointmentStageByStringId: (state, action: PayloadAction<AppointmentStage>) => {
      const appointmentStages =
        state.treatmentAppointments.appointments[action.payload.appointmentId].appointmentStage

      const updateStages = appointmentStages.filter((stage) => stage.id !== action.payload.id)

      state.treatmentAppointments.appointments[action.payload.appointmentId].appointmentStage =
        updateStages
    },
    moveAppointments: (state, action: PayloadAction<PayloadMoveAppointments>) => {
      const { sourceIndex, destinationIndex } = action.payload
      state.needAppointmentSave = true
      // Преобразуем объект appointments в массив для удобства работы
      const appointmentsArray = Object.values(state.treatmentAppointments.appointments)

      // Сортируем массив по sortOrder
      appointmentsArray.sort((a, b) => a.sortOrder - b.sortOrder)

      const [removedAppointment] = appointmentsArray.splice(sourceIndex, 1)

      // Вставляем объект на новую позицию
      appointmentsArray.splice(destinationIndex, 0, removedAppointment)

      // Пересчитываем sortOrder для всех appointments в новом порядке
      const updatedAppointmentsArray = appointmentsArray.map((appointment, index) => ({
        ...appointment,
        sortOrder: index,
      }))

      // Преобразуем массив обратно в объект с ключами, соответствующими id каждого appointment
      const updatedAppointments = updatedAppointmentsArray.reduce((acc, appointment) => {
        acc[appointment.id] = appointment
        return acc
      }, {})

      // Обновляем appointments в состоянии новым объектом с обновленными sortOrder
      state.treatmentAppointments.appointments = updatedAppointments
    },
    updateAdditionalInfoStage: (state, action: PayloadAction<PayloadAppointmentStage>) => {
      const appointment = state.treatmentAppointments.appointments[action.payload.appointmentId]
      if (!appointment) return
      const stage = appointment.appointmentStage.find((el) => el.id === action.payload.id)
      if (!stage) return

      if (action.payload.price) stage.additionalInfo.price = action.payload.price
      if (action.payload.duration) stage.additionalInfo.duration = action.payload.duration
    },
    toggleMultiChoice: (state) => {
      if (state.multiChoice) {
        state.selectedTeeth = []
      }
      state.multiChoice = !state.multiChoice
    },
    disableMultiChoice: (state) => {
      state.multiChoice = false
      state.selectedTeeth = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTreatmentPlanById.fulfilled, (state, action) => {
        state.treatmentPlan = action.payload
        state.selectedTooth = null
      })
      .addCase(changeTreatmentPlan.fulfilled, (state, action) => {
        state.treatmentPlan.name = action.payload.name
        state.treatmentPlan.description = action.payload.description
      })
      .addCase(createToothPriority.fulfilled, (state, action) => {
        action.payload.map(
          (priority) =>
            (state.treatmentPlan.teeth[priority.toothId.toString()].priority = priority),
        )
      })
      .addCase(deleteToothPriority.fulfilled, (state, action) => {
        action.payload.forEach(
          (priority) => (state.treatmentPlan.teeth[priority.toothId.toString()].priority = {}),
        )
      })
      .addCase(createToothDiagnose.fulfilled, (state, action) => {
        Object.entries(action.payload).forEach(([toothId, diagnoses]) => {
          state.treatmentPlan.teeth[toothId].patientDiagnoses = diagnoses
        })
      })
      .addCase(deleteToothDiagnose.fulfilled, (state, action) => {
        Object.entries(action.payload).forEach(([toothId, diagnoses]) => {
          const diagnosis = state.treatmentPlan?.teeth[toothId]?.patientDiagnoses

          if (!diagnosis.length) return

          state.treatmentPlan.teeth[toothId].patientDiagnoses = diagnosis.filter(
            (el) => !diagnoses.includes(el.id),
          )
        })
      })
      .addCase(createVariant.fulfilled, (state, action) => {
        const variantId = action.payload.id
        const toothId = action.payload.toothId

        if (!state.treatmentPlan.teeth[toothId].treatmentVariants[variantId]) {
          state.treatmentPlan.teeth[toothId].treatmentVariants[variantId] = action.payload
        }
      })
      .addCase(deleteVariant.fulfilled, (state, action) => {
        const variantId = action.payload.variant.id
        const toothId = action.payload.toothId

        delete state.treatmentPlan.teeth[toothId].treatmentVariants[variantId]
      })
      .addCase(createToothTreatment.fulfilled, (state, action) => {
        Object.entries(action.payload).forEach(([toothId, variants]) => {
          const tooth = state.treatmentPlan?.teeth[toothId]

          if (!tooth) return

          variants.forEach((variant) => {
            if (!tooth.treatmentVariants[variant.id]) {
              return
            }

            tooth.treatmentVariants[variant.id].patientTreatments = variant.patientTreatments
          })
        })
      })
      .addCase(deleteToothTreatment.fulfilled, (state, action) => {
        Object.entries(action.payload).forEach(([toothId, treatments]) => {
          const tooth = state.treatmentPlan?.teeth[toothId]

          if (!tooth) return

          treatments.forEach((treatment) => {
            const variant = tooth.treatmentVariants[treatment.treatmentVariantId]

            variant.patientTreatments = variant.patientTreatments.filter(
              (t) => t.id !== treatment.id,
            )
          })
        })
      })
      .addCase(addTreatmentPlanImage.fulfilled, (state, action) => {
        state.treatmentPlan.treatmentImages[action.payload.id] = action.payload
      })
      .addCase(deleteTreatmentPlanImage.fulfilled, (state, action) => {
        delete state.treatmentPlan.treatmentImages[action.payload.id]
      })
      .addCase(saveMetadataTreatmentPlanImage.fulfilled, (state, action) => {
        state.treatmentPlan.treatmentImages[action.payload.id] = action.payload
      })
      .addCase(changeMainVariant.fulfilled, (state, action) => {
        const toothId = action.payload.newMainVariant.toothId
        const newVariantId = action.payload.newMainVariant.id
        const oldVariantId = action.payload.oldMainVariant.id

        state.treatmentPlan.teeth[toothId].treatmentVariants[newVariantId].isMain =
          action.payload.newMainVariant.isMain
        state.treatmentPlan.teeth[toothId].treatmentVariants[oldVariantId].isMain =
          action.payload.oldMainVariant.isMain
      })
      .addCase(changeRotationTreatmentPlanImage.fulfilled, (state, action) => {
        state.treatmentPlan.treatmentImages[action.payload.id] = action.payload
      })
      .addCase(getTreatmentAppointmentsById.pending, (state) => {
        state.treatmentAppointments = null
      })
      .addCase(getTreatmentAppointmentsById.fulfilled, (state, action) => {
        state.treatmentAppointments = action.payload
      })
      .addCase(createNewAppointment.fulfilled, (state, action) => {
        state.treatmentAppointments.appointments[action.payload.id] = action.payload
      })
      .addCase(deleteAppointment.fulfilled, (state, action) => {
        delete state.treatmentAppointments.appointments[action.payload.appointment.id]
      })
      .addCase(saveStagesIntoAppointments.fulfilled, (state, action) => {
        if (state.treatmentAppointments?.appointments) {
          state.treatmentAppointments.appointments = action.payload.appointments
          state.needAppointmentSave = false
        }
      })
      .addCase(deleteAppointmentStageById.fulfilled, (state, action) => {
        const appointmentStages =
          state.treatmentAppointments.appointments[action.payload.stage.appointmentId]
            .appointmentStage

        const updateStages = appointmentStages.filter(
          (stage) => stage.id !== action.payload.stage.id,
        )

        state.treatmentAppointments.appointments[
          action.payload.stage.appointmentId
        ].appointmentStage = updateStages
      })
      .addCase(deleteAllAppointments.fulfilled, (state, action) => {
        state.treatmentAppointments.appointments = action.payload
      })
      .addCase(addReviewData.fulfilled, (state, action) => {
        state.treatmentPlan.doctors = action.payload.doctors
        state.treatmentPlan.equipments = action.payload.equipments
        state.treatmentPlan.portfolios = action.payload.portfolios
      })
      .addCase(changeToothInfo.fulfilled, (state, action) => {
        const tooth = state.treatmentPlan.teeth[action.payload.toothId]
        if (!tooth) return
        tooth.description = action.payload.description
        tooth.priority = action.payload.priority
      })
      .addCase(getToothDescription.fulfilled, (state, action) => {
        const tooth = state.treatmentPlan.teeth[action.payload.toothId]
        if (!tooth) return
        tooth.description = action.payload.description
        tooth.descriptionTranslate = action.payload.descriptionTranslate
      })
  },
})

export const treatmentPlanAction = treatmentPlanSlice.actions
export const treatmentPlanReducer = treatmentPlanSlice.reducer
