import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

interface Credentials {
  treatmentPlanId: number
  ids: number[]
}

export const deleteToothDiagnose = createAsyncThunk<Record<string, number[]>, Credentials>(
  'treatmentPlan/delete-tooth-diagnose',
  async (credentials, thunkAPI) => {
    try {
      const ids = `?ids=${credentials.ids}`

      const response = await axios.delete(`/patient-diagnosis/${credentials.treatmentPlanId}${ids}`)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
