import { CurrencyType, Stage } from 'store/settings/settings.state'

import { Crop } from 'react-image-crop'
import { ITranslateObject } from 'store/treatment/treatment.types'
import { JawType } from 'modules/treatment/components/ImageOfPatientInfoPopup/ImageOfPatientInfoPopup.types'

export enum LevelType {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
}

export enum PriorityType {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
  NONE = 'none',
}

export interface NameTranslate {
  en: string
  de: string
  uk: string
  cz: string
}

export type ToothNumber =
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'
  | '13'
  | '14'
  | '15'
  | '16'
  | '17'
  | '18'
  | '19'
  | '20'
  | '21'
  | '22'
  | '23'
  | '24'
  | '25'
  | '26'
  | '27'
  | '28'
  | '29'
  | '30'
  | '31'
  | '32'

export interface PatientDiagnose {
  diagnosisSpecifierId: number
  id: number
  toothId: number
  treatmentToothId: number
  diagnosisId: number
  subDiagnosisId?: number | null
  createdAt: string
  updateAt: string
}
export interface PatientTreatment {
  id: number
  treatmentVariantId: number
  treatmentId: number
  subTreatmentId?: number | null
  createdAt: string
  updateAt: string
}

export interface TreatmentVariant {
  id: number
  toothId: number
  sortOrder: number
  treatmentPlanId: number
  isMain: boolean
  createdAt: string
  updateAt: string
  patientTreatments: PatientTreatment[]
}

export interface TreatmnentImageMetadata {
  ratio: number
  type: JawType
  teeth: { toothId: number; x: number; y: number }[]
  rotation?: number
  crop?: Crop
}

export interface TreatmnentImage {
  id: number
  path: string
  sortOrder: number
  treatmentPlanId: number
  createdAt: string
  updateAt: string
  metadata: TreatmnentImageMetadata
}

export interface Priority {
  id: number
  toothId: number
  treatmentPlanId: number
  level: LevelType
  createdAt: string
  updateAt: string
}

export interface ToothDetail {
  id: number
  toothId: number
  description: string
  descriptionTranslate: ITranslateObject
  treatmentPlanId: number
  priority: PriorityType
  patientDiagnoses: PatientDiagnose[]
  treatmentVariants?: { [key: string]: TreatmentVariant }
}

export interface AppointmentPatientTreatment extends PatientTreatment {
  stages: Stage[]
  treatment: {
    name: string
    categoryId: number
    translate: NameTranslate
  }
  subTreatment: null | {
    name: string
    translate: NameTranslate
  }
}

export interface AppointmentToothDetail {
  toothId: number
  patientTreatments: AppointmentPatientTreatment
}

export type Teeth = {
  [K in ToothNumber]: ToothDetail
}

type AppointmentTeeth = {
  [K in ToothNumber]: AppointmentToothDetail
}

export interface AppointmentStage {
  id?: number | string
  sortOrder: number
  toothId: number
  appointmentId: number
  patientTreatmentId: number
  additionalInfo: {
    duration?: number
    price?: number
    currency?: CurrencyType
  }
  name: string
  price: number
  currency: CurrencyType
  duration: number
  stageId: number
  createdAt?: string
  updateAt?: string
}

export interface IAppointment {
  id: number
  name: string
  sortOrder: number
  treatmentPlanId: number
  appointmentStage: AppointmentStage[]
  createdAt: string
  updateAt: string
}

export type Appointments = {
  [key: number]: IAppointment
}

export interface TreatmentPlan {
  id: number
  name: string
  nameTranslate: NameTranslate
  description: string
  patientId: number
  createdAt: string
  updateAt: string
  doctors: Array<number>
  equipments: Array<number>
  portfolios: Array<number>
  teeth: Teeth
  treatmentImages: { [key: string]: TreatmnentImage }
}

export interface TreatmentAppointments {
  teeth: AppointmentTeeth
  appointments: Appointments
}

export interface TreatmentPlanState {
  treatmentPlan: TreatmentPlan
  treatmentAppointments: TreatmentAppointments
  selectedTooth: number | null
  multiChoice: boolean
  selectedTeeth: number[]
  needAppointmentSave: boolean
  navigationPath: string | null
}

export const initialState: TreatmentPlanState = {
  treatmentPlan: null,
  selectedTooth: null,
  treatmentAppointments: null,
  needAppointmentSave: false,
  multiChoice: false,
  selectedTeeth: [],
  navigationPath: null,
}
