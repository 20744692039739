import { PatientTreatment } from '../treatmentPlan.state'
import { ThunkApiConfig } from 'store/auth/operations/signIn.operation'
import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

interface Request {
  ids: number[]
  treatmentPlanId: number
  allowDenition?: boolean
}

interface Response {
  treatments: Record<string, PatientTreatment[]>
  response?: {
    status: number
  }
}

export const deleteToothTreatment = createAsyncThunk<Response, Request, ThunkApiConfig>(
  'treatmentPlan/delete-tooth-treatment',
  async (credentials, thunkAPI) => {
    try {
      const ids = `?ids=${credentials.ids}`
      const denition = credentials.allowDenition ? '&allowDeletion=true' : ''

      const response = await axios.delete(
        `/patient-treatments/${credentials.treatmentPlanId}${ids}${denition}`,
      )

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
