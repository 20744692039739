import { TreatmentVariant } from '../treatmentPlan.state'
import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

interface Request {
  teeth: number[]
  treatmentId: number
  subTreatmentId?: number
  treatmentPlanId: number
  treatmentVariantId: number[]
}

export const createToothTreatment = createAsyncThunk<Record<string, TreatmentVariant[]>, Request>(
  'treatmentPlan/create-tooth-treatment',
  async (credentials, thunkAPI) => {
    try {
      const response = await axios.post('/patient-treatments', credentials)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
